import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_Nav = _resolveComponent("Nav")
  const _component_notifications = _resolveComponent("notifications")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.auth)
      ? (_openBlock(), _createBlock(_component_Nav, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_notifications),
    _createVNode(_component_router_view)
  ], 64))
}